import React, { Component } from 'react';
import TaskSticker from './TaskSticker';

export class BlockItem extends Component {
  _isMounted = false;
  state = {
    hour: 0,
    now: new Date(),
    blockTasks: [],
    running: false
  };
  componentDidMount() {
    this._isMounted = true;
    const findedBlockTasks = this.findBlockTasks(this.props.block);
    const blockTasks = findedBlockTasks.map(block => {
      return { ...block, running: false };
    });
    this.startTime();
    const hour = this.props.now.getHours();
    const now = this.props.now;
    this.setState({
      ...this.state,
      blockTasks,
      hour,
      now
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.plans !== prevProps.plans) {
      const findedBlockTasks = this.findBlockTasks(this.props.block);
      const blockTasks = findedBlockTasks.map(task => {
        return { ...task, running: false };
      });
      this.setState({
        ...this.state,
        blockTasks
      });
      this.checkIfBlockTasksAreRunning(
        this.getTime(this.props.now),
        blockTasks
      );
    }
  }
  getTime = now => {
    const h = now.getHours();
    const m = now.getMinutes();
    const s = now.getSeconds();
    const time = h * 60 * 60 + m * 60 + s;
    return time;
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  findBlockTasks = block => {
    return this.props.plans.filter(plan => {
      const startAt = new Date(plan.startAt);
      if (plan.type === 'task') {
        return this.checkPlanIsRepeat(startAt, plan.startTime, plan, block);
      } else if (plan.type === 'event') {
        const startTime =
          startAt.getHours() * 60 * 60 +
          startAt.getMinutes() * 60 +
          startAt.getSeconds();
        return this.checkPlanIsRepeat(startAt, startTime, plan, block);
      }
      return false;
    });
  };
  checkPlanIsRepeat = (startAt, startTime, plan, block) => {
    if (!plan.isRepeat) {
      if (
        startAt.getFullYear() === block.date.getFullYear() &&
        startAt.getMonth() === block.date.getMonth() &&
        startAt.getDate() === block.date.getDate()
      ) {
        return this.checkPlanIsInBlock(startTime, plan, block);
      }
    } else if (plan.isRepeat && plan.repeatFrequency === 'daily') {
      return this.checkPlanIsInBlock(startTime, plan, block);
    } else if (plan.isRepeat && plan.repeatFrequency === 'weekly') {
      if (startAt.getDay() === block.date.getDay()) {
        return this.checkPlanIsInBlock(startTime, plan, block);
      }
    } else if (plan.isRepeat && plan.repeatFrequency === 'monthly') {
      if (startAt.getDate() === block.date.getDate()) {
        return this.checkPlanIsInBlock(startTime, plan, block);
      }
    } else if (plan.isRepeat && plan.repeatFrequency === 'yearly') {
      if (
        startAt.getMonth() === block.date.getMonth() &&
        startAt.getDate() === block.date.getDate()
      ) {
        return this.checkPlanIsInBlock(startTime, plan, block);
      }
    }
  };
  checkPlanIsInBlock = (startTime, plan, block) => {
    const endTask = (startTime + plan.duration) % 86400;
    if (block.startTime <= startTime && block.endTime > startTime) {
      // return if task starts in block
      return { ...plan, hidden: false };
    } else if (
      block.startTime > startTime &&
      block.endTime <= endTask &&
      block.endTime !== 0
      //&& block.running
    ) {
      // return if block is inside task
      return { ...plan, hidden: true };
    } else if (
      block.startTime > startTime &&
      endTask < block.endTime &&
      endTask > block.startTime
      //&& block.running
    ) {
      // return if task ends in block
      return { ...plan, hidden: true };
    } else {
      return false;
    }
  };
  startTime = () => {
    if (this.props.isMounted && this._isMounted) {
      const time = this.getTime(this.props.now);
      const hour = this.props.now.getHours();
      this.checkIfBlockTasksAreRunning(time, this.state.blockTasks);
      setTimeout(this.startTime, 1000);
      if (this.state.hour !== hour && this.props.block.running === true) {
        this.props.moveToNextBlock();
      }
      if (this.state.hour !== hour) {
        this.setState({ ...this.state, hour });
        const blockTasks = this.findBlockTasks(this.props.block);
        blockTasks.map(task => (task.running = false));
        this.setState({ ...this.state, blockTasks });
      }
    }
  };
  checkIfBlockTasksAreRunning = (time, plans) => {
    let update = false;
    if (plans.length > 0) {
      const blockTasks = plans.map(plan => {
        let startTime = plan.startTime;
        if (plan.type === 'event') {
          const eventDate = new Date(plan.startAt);
          startTime =
            eventDate.getHours() * 60 * 60 +
            eventDate.getMinutes() * 60 +
            eventDate.getSeconds();
        }
        const endTime = startTime + plan.duration;
        if (
          startTime <= time &&
          time < endTime &&
          time >= this.props.block.startTime &&
          this.props.block.endTime > time
        ) {
          // task is running
          if (plan.running === false) {
            plan.running = true;
            update = true;
          }
        } else {
          // task is not running
          if (plan.running === true) {
            plan.running = false;
            update = true;
          }
        }
        return plan;
      });

      if (update) {
        const activeTasks = blockTasks.filter(task => {
          return task.running === true;
        });
        // update state redux
        this.props.updateRunningPlans(activeTasks);
      }
    }
  };
  secondsToDhms = seconds => {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    const time = {
      d,
      h,
      m,
      s
    };
    return time;
  };
  getSecondsCssRem = seconds => {
    const min = this.secondsToDhms(seconds).m;
    const rem = Math.round(((min * 6) / 60 + Number.EPSILON) * 100) / 100;
    return rem + 'rem';
  };
  render() {
    const { date, running } = this.props.block;
    return (
      <li
        className={
          running
            ? 'task-item active position-relative'
            : 'task-item position-relative'
        }
      >
        <div className="row ml-1">
          <div className="col-10">
            {this.state.blockTasks.map((task, index) => {
              return (
                <TaskSticker
                  key={task.id}
                  task={task}
                  block={this.props.block}
                  time={this.state.time}
                  cols={this.state.blockTasks.length}
                  position={index}
                />
              );
            })}
          </div>
        </div>

        <div className="task-container row h-100">
          <span className="item-thumb-container col-3 col-md-2"></span>
          <span className="item-details-container col-7 col-md-8">
            <div className="task-title"></div>
            <div className="task-description"></div>
          </span>
          <span className="item-side-container col-2 ">
            {!running
              ? new Intl.DateTimeFormat('en-US', {
                  day: 'numeric',
                  weekday: 'short',
                  hour: 'numeric'
                }).format(date)
              : new Intl.DateTimeFormat('en-US', {
                  day: 'numeric',
                  weekday: 'short'
                }).format(date)}
            <br />
            {running
              ? new Intl.DateTimeFormat('en-US', {
                  hour: '2-digit',
                  minute: '2-digit'
                }).format(this.props.now)
              : ''}
            <br />
            {running ? (
              <div
                className="time-dot round position-absolute"
                style={{
                  right: '1px',
                  height: '2px',
                  width: '50%',
                  backgroundColor: 'rgb(210, 229, 39)',
                  borderColor: 'rgb(210, 229, 39)',
                  top: this.getSecondsCssRem(this.getTime(this.props.now))
                }}
              ></div>
            ) : (
              ''
            )}
          </span>
        </div>
      </li>
    );
  }
}

export default BlockItem;
