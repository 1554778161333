import axios from 'axios';

export const graphql = query => {
  const token = localStorage.getItem('access_token');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };

  const body = {
    query
  };

  return axios.post(
    `${process.env.REACT_APP_API_URL}/api/graphql`,
    JSON.stringify(body),
    config
  );
};

export const get = url => {
  const token = localStorage.getItem('access_token');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };

  return axios.get(url, config);
};

export const post = (url, body) => {
  const token = localStorage.getItem('access_token');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };

  return axios.post(url, body, config);
};

export const put = (url, body) => {
  const token = localStorage.getItem('access_token');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };

  return axios.put(url, body, config);
};

export const deletion = url => {
  const token = localStorage.getItem('access_token');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };

  return axios.delete(url, config);
};
