import { returnErrors, createMessage } from './messages';
import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS
} from './types';
import { get, post } from '../helpers/Call';

// CHECK TOKEN & LOAD USER
export const loadUser = () => dispatch => {
  // User Loading
  dispatch({ type: USER_LOADING });
  get(`${process.env.REACT_APP_API_URL}/auth/user`)
    .then(res => {
      dispatch({
        type: USER_LOADED,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: AUTH_ERROR
      });
    });
};

// REGISTER USER
export const registerUser = user => dispatch => {
  return post(`${process.env.REACT_APP_API_URL}/api/users/`, user)
    .then(res => {
      dispatch(
        createMessage({ success: 'Congratulations, You have registered!' })
      );
      return user;
    })
    .then(res => {
      const obj = {
        grant_type: 'password',
        client_id: 'client_test',
        client_secret: 'test',
        scope: 'test',
        username: res.email,
        password: res.password
      };
      const body = JSON.stringify(obj);

      post(`${process.env.REACT_APP_API_URL}/auth/token`, body)
        .then(res => {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
          });
          // User Loading
          dispatch({ type: USER_LOADING });
          get(`${process.env.REACT_APP_API_URL}/auth/user`)
            .then(res => {
              dispatch({
                type: USER_LOADED,
                payload: res.data
              });
            })
            .catch(err => {
              dispatch({
                type: AUTH_ERROR
              });
            });
        })
        .catch(err => {
          dispatch({
            type: LOGIN_FAIL
          });
        });
    })
    .catch(err => {
      console.log(err);
      //dispatch(returnErrors(err.response.data, err.response.status));
    });
};

// LOGIN USER
export const login = (username, password) => dispatch => {
  const obj = {
    grant_type: 'password',
    client_id: 'client_test',
    client_secret: 'test',
    scope: 'test',
    username: username,
    password: password
  };
  const body = JSON.stringify(obj);

  post(`${process.env.REACT_APP_API_URL}/auth/token`, body)
    .then(res => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      });
      // User Loading
      dispatch({ type: USER_LOADING });
      get(`${process.env.REACT_APP_API_URL}/auth/user`)
        .then(res => {
          dispatch({
            type: USER_LOADED,
            payload: res.data
          });
        })
        .catch(err => {
          dispatch({
            type: AUTH_ERROR
          });
        });
    })
    .catch(err => {
      if (err.response) {
        dispatch(returnErrors(err.response.data, err.response.status));
      } else {
        dispatch(returnErrors({ error: '500', detail: 'network error' }, 500));
      }
    });
};

// LOGOUT USER
export const logout = () => dispatch => {
  get(`${process.env.REACT_APP_API_URL}/auth/logout`)
    .then(res => {
      dispatch({
        type: LOGOUT_SUCCESS
      });
    })
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};
