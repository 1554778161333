import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { registerUser } from '../../actions/auth';
import { Redirect } from 'react-router-dom';

export class Register extends Component {
  state = {
    user: { username: '', email: '', password: '', password2: '' }
  };

  static propTypes = {
    user: PropTypes.object
  };

  onSubmit = e => {
    e.preventDefault();
    const user = this.state.user;
    this.props.registerUser(user);
  };

  onChange = e =>
    this.setState({
      user: { ...this.state.user, [e.target.name]: e.target.value }
    });

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }
    const { email, password, password2 } = this.state.user;
    return (
      <div className="container-login pt-5">
        <div className="col-md-6 col-lg-4 m-auto d-flex text-light">
          <div className=" card-body mt-2">
            <h2 className="text-center">Register</h2>
            <div className="pt-4">
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    onChange={this.onChange}
                    value={email}
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    onChange={this.onChange}
                    value={password}
                  />
                </div>
                <div className="form-group">
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password2"
                    onChange={this.onChange}
                    value={password2}
                  />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-secondary">
                    Register
                  </button>
                </div>
                <p>
                  Already have an account? <Link to="/login">Login</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { registerUser })(Register);
