import { combineReducers } from 'redux';
import errors from './errors';
import messages from './messages';
import auth from './auth';
import users from './users';
import plans from './plans';

export default combineReducers({
  errors,
  messages,
  auth,
  users,
  plans
});
