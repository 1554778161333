import { returnErrors } from './messages';
import { get } from '../helpers/Call';

import { GET_USERS } from './types';

// GET USERS
export const getUsers = () => dispatch => {
  get(`${process.env.REACT_APP_API_URL}/api/users/`)
    .then(res => {
      dispatch({
        type: GET_USERS,
        payload: res.data._embedded.users
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};
