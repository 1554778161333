import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class MenuList extends Component {
  render() {
    return (
      <>
        {this.props.menuLinks.map(link => {
          return (
            <li className="nav-item" key={link.to}>
              <Link
                to={link.to}
                className="nav-link btn text-dark"
                onClick={this.props.handleCloseMenu}
              >
                <div className="row">
                  <div className="col-6">
                    <i className={link.iconClass} style={{ width: '40px' }}></i>
                  </div>
                  {this.props.showTitles ? (
                    <div className="col-6 text-left">
                      <strong>{link.title}</strong>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Link>
            </li>
          );
        })}
        <li className="nav-item">
          <Link to="/profile" className="nav-link btn text-dark">
            <div className="row">
              <div className="col-6">
                <i className="fas fa-user fa-2x" style={{ width: '40px' }}></i>
              </div>
              <div className="col-6 text-left">
                {this.props.showTitles ? (
                  <strong>{this.props.user.displayName}</strong>
                ) : (
                  ''
                )}
              </div>
            </div>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/"
            className="nav-link btn text-dark"
            onClick={this.props.logout}
          >
            <div className="row">
              <div className="col-6">
                <i
                  className="fas fa-sign-out-alt fa-2x"
                  style={{ width: '40px' }}
                ></i>
              </div>
              <div className="col-6 text-left">
                {this.props.showTitles ? <strong>logout</strong> : ''}
              </div>
            </div>
          </Link>
        </li>
      </>
    );
  }
}

export default MenuList;
