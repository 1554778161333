import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    backgroundColor: 'transparent',
    margin: 0
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}));

export default function EventChips(props) {
  const classes = useStyles();
  const [chipData, setChipData] = useState(props.filterTags);
  const handleDelete = chipToDelete => () => {
    props.removeFilter(chipToDelete);
  };
  useEffect(() => {
    if (chipData !== props.filterTags) {
      setChipData(filter => props.filterTags);
    }
  });
  return (
    <ul className={classes.root}>
      {chipData.map(tag => {
        let icon;
        return (
          <li key={tag}>
            <Chip
              icon={icon}
              label={tag === '' ? 'none' : tag}
              onDelete={handleDelete(tag)}
              className={classes.chip}
            />
          </li>
        );
      })}
    </ul>
  );
}
