import React, { Component } from 'react';
import './css/TableCard.css';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

export class TableCard extends Component {
  state = {
    open: false
  };
  handleTooltipClose = () => {
    this.setState({ open: false });
  };

  handleTooltipOpen = () => {
    this.setState({ open: true });
  };
  render() {
    return (
      <table style={{ height: '450px', width: '100%' }}>
        <tbody>
          <tr>
            <td className="align-middle text-allign-center w-100">
              <ClickAwayListener onClickAway={this.handleTooltipClose}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true
                    }}
                    onClose={this.handleTooltipClose}
                    open={this.state.open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={this.props.info}
                  >
                    <div
                      className="round-label-sticker rounded-circle"
                      onClick={this.handleTooltipOpen}
                    >
                      <i className="fas fa-info"></i>
                    </div>
                  </Tooltip>
                </div>
              </ClickAwayListener>
              <Link to={this.props.link}>
                <button className="btn btn-primary btn-lg text-uppercase display-4 font-weight-bold w-75">
                  {this.props.text}
                </button>
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default TableCard;
