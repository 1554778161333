import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import PropTypes, { object } from 'prop-types';
import { getMyPlans } from '../../actions/plans';
import { returnErrors } from '../../actions/messages';
import EventCard from '../plans/EventCard';
import './css/MyLists.css';
import Loading from './Loading';

class EventList extends Component {
  static propTypes = {
    user: object.isRequired,
    returnErrors: PropTypes.func.isRequired,
    myPlans: PropTypes.array
  };
  state = {
    myPlans: [],
    events: [],
    tasks: [],
    page: 1,
    maxResult: 100,
    totItems: 0,
    totPages: 0,
    pages: []
  };
  componentDidMount() {
    const from = new Date();
    from.setDate(from.getDate() - 1);
    this.getRemotePlans(from);
  }
  getRemotePlans = async from => {
    const myPlans = await this.props.getMyPlans(from, this.props.user.id);
    const events = myPlans.filter(plan => plan.type === 'event');
    const tasks = myPlans.filter(plan => plan.type === 'task');
    this.setState({
      ...this.state,
      myPlans,
      events,
      tasks
    });
  };
  formatDate(d) {
    const date = new Date(d.date);
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };

    return date.toLocaleDateString('it-IT', options);
  }
  render() {
    return (
      <Fragment>
        {this.state.tasks.length > 0 ? (
          <div className="row mb-3">
            <div className="col">
              <div className="pl-3">
                <h1 className="events-lable">My events</h1>
                <div className="event-list-container scrolling-wrapper scroll-style m-0">
                  <ul className="event-list p-0">
                    {this.state.events.map(event => (
                      <EventCard key={event.id} event={event} />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {this.state.tasks.length > 0 ? (
          <div className="row mt-3">
            <div className="col">
              <div className="pl-3">
                <h1 className="events-lable">My tasks</h1>
                <div className="event-list-container scrolling-wrapper scroll-style m-0">
                  <ul className="event-list p-0">
                    {this.state.tasks.map(task => (
                      <EventCard key={task.id} event={task} />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  myPlans: state.plans.myPlans
});

export default connect(mapStateToProps, {
  returnErrors,
  getMyPlans
})(EventList);
