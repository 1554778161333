import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Loading from '../layout/Loading';

const PrivateRoute = ({ component: Component, auth, load, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (auth.isLoading) {
        return <Loading height={'500px'} />;
      }
      if (!auth.isAuthenticated) {
        return <Redirect to="/login" />;
      } else {
        return !load ? (
          <Component key={props.history.location.pathname} {...props} />
        ) : (
          <Component key="/" {...props} />
        );
      }
    }}
  />
);

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
