import React, { Component } from 'react';
import './css/TaskDisplay.css';

export class TaskDisplay extends Component {
  state = {
    task: {
      title: '',
      description: '',
      startFrom: 0,
      duration: 0,
      cols: 12,
      position: 0,
      category: 'default'
    }
  };
  componentDidMount() {
    this.setState({ ...this.state, task: this.props.task });
  }
  render() {
    const { title, cols, position, category } = this.props.task;
    return (
      <div
        className="running-task-display position-absolute text-center h-100 overflow-hidden p-2"
        style={{
          width: parseInt(100 / cols) + '%',
          left: parseInt(100 / cols) * position + '%',
          backgroundImage: `linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 1)
          ),
          url(${window.location.origin}/img/categories/${category}/large.jpg)`
        }}
      >
        <span
          className="task-title text-uppercase font-weight-bold"
          style={{ fontSize: 100 / this.props.nRunning / 2 }}
        >
          {title}
        </span>
      </div>
    );
  }
}

export default TaskDisplay;
