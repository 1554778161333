import React, { Component } from 'react';
import Form from './PlanForm';

export class EditEvent extends Component {
  componentDidMount() {}
  render() {
    return (
      <div>
        <Form id={parseInt(this.props.match.params.id)} title={'edit'} />
      </div>
    );
  }
}

export default EditEvent;
