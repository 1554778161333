import React, { Component } from 'react';
import './css/Dashboard.css';
import TaskList from '../blocks/TaskList';
import { connect } from 'react-redux';
import TaskDisplay from '../blocks/TaskDisplay';

export class Dashboard extends Component {
  state = {
    runningTasks: []
  };
  componentDidMount() {
    this.setRunningTasks(this.props.runningTasks);
  }
  componentDidUpdate(prevState) {
    if (prevState.runningTasks !== this.props.runningTasks) {
      this.setRunningTasks(this.props.runningTasks);
    }
  }
  setRunningTasks = tasks => {
    const runningTasks = tasks.map((task, index) => {
      return { ...task, position: index, cols: tasks.length };
    });
    this.setState({
      ...this.state,
      runningTasks
    });
  };
  render() {
    return (
      <>
        <div
          className="row"
          style={{
            backgroundColor: 'black',
            height: '60vh'
          }}
        >
          <div
            className="col-12 col-md-8 col-lg-6 col-xl-5 container-thumb"
            style={{
              height: '80%',
              backgroundSize: 'cover'
            }}
          >
            <div
              className="thumbnail"
              style={{
                height: '100%'
              }}
            >
              {this.state.runningTasks.map(task => {
                return (
                  <TaskDisplay
                    key={task.id}
                    task={task}
                    nRunning={this.state.runningTasks.length}
                  />
                );
              })}
            </div>
          </div>
        </div>

        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%'
          }}
        >
          <TaskList />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  runningTasks: state.plans.runningPlans
});

export default connect(mapStateToProps, {})(Dashboard);
