import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addPlan, getPlan, updatePlan } from '../../actions/plans';
import { returnErrors } from '../../actions/messages';
import { withRouter } from 'react-router-dom';
import { validateForm, validateField } from '../form/FormValidator';
import './css/EventForm.css';
import { get } from '../../helpers/Call';
import GeneralForm from '../form/Form';

class PlanForm extends Component {
  state = {
    event: {
      id: null,
      type: '',
      category: '',
      title: '',
      public: false,
      repeat: this.props.type === 'task' ? true : false,
      repeatFrequency: 'weekly',
      startAt: null,
      startTime: 0,
      description: '',
      duration: 3600,
      price: 0,
      userId: ''
    },
    updated: false,
    formHasErrors: false,
    errors: {},
    formModel: [
      {
        name: 'title',
        label: 'Title',
        inputType: 'text'
      },
      {
        name: 'startAt',
        label: 'Start At',
        inputType: 'dateTime'
      },
      {
        name: 'duration',
        label: 'Duration',
        inputType: 'duration'
      },
      {
        name: 'category',
        label: 'Category',
        inputType: 'select',
        options: [
          { name: 'gym', value: 'gym' },
          { name: 'concert', value: 'concert' },
          { name: 'default', value: 'default' }
        ]
      },
      {
        name: 'description',
        label: 'Description',
        inputType: 'text'
      },
      {
        name: 'public',
        label: 'check for this to be visible to everyone',
        inputType: 'switch',
        options: {
          unchecked: 'private',
          checked: 'public'
        }
      },
      {
        name: 'repeat',
        label: 'it repeats',
        inputType: 'switch',
        options: {
          unchecked: "it doesn' repeat",
          checked: 'it repeats'
        }
      },
      {
        name: 'repeatFrequency',
        classes: this.props.type === 'task' ? '' : 'd-none',
        label: 'Radio',
        inputType: 'radio',
        options: [
          {
            name: 'daily',
            value: 'daily'
          },
          {
            name: 'weekly',
            value: 'weekly'
          },
          {
            name: 'monthly',
            value: 'monthly'
          },
          {
            name: 'yearly',
            value: 'yearly'
          }
        ]
      }
    ],
    validationModel: []
  };
  static propTypes = {
    id: PropTypes.number,
    getEvent: PropTypes.func,
    addPlan: PropTypes.func.isRequired,
    updatePlan: PropTypes.func.isRequired
  };
  componentDidMount() {
    this.getEventForm();
    if (this.props.id) {
      this.setState({ event: { ...this.state.event, id: this.props.id } });
      this.getRemotePlan(this.props.id);
    } else {
      this.setState({
        ...this.state,
        event: {
          ...this.state.event,
          type: this.props.type,
          userId: this.props.auth.user.id
        }
      });
    }
  }
  getRemotePlan = async id => {
    const plan = await this.props.getPlan(id);
    const {
      id: planId,
      type,
      title,
      description,
      public: isPublic,
      repeat,
      repeatFrequency,
      category,
      startAt: { date: startAt },
      startTime,
      duration,
      price,
      organizer: { id: userId }
    } = plan;
    const formModel = repeat
      ? this.state.formModel.map(input => {
          if (input.name === 'repeatFrequency') {
            input.classes = '';
          }
          return input;
        })
      : this.state.formModel;

    this.setState({
      ...this.state,
      event: {
        ...this.state.event,
        id: planId,
        type,
        title,
        description,
        category,
        public: isPublic,
        repeat,
        repeatFrequency,
        startAt: Date.parse(startAt),
        startTime,
        description,
        duration,
        price,
        userId
      },
      formModel
    });
  };
  getEventForm = () => {
    get(`${process.env.REACT_APP_API_URL}/api/events/form`)
      .then(res => {
        this.setState({
          ...this.state,
          validationModel: res.data
        });
      })
      .catch(err => returnErrors(err));
  };
  onChange = (name, value) => {
    switch (name) {
      case 'repeat':
        this.onChangeRepeat(name, value);
        break;
      case 'startAt':
        this.onChageStartAt(name, value);
        break;
      default:
        this.setState({
          ...this.state,
          event: {
            ...this.state.event,
            [name]: value
          },
          selected: value
        });
        break;
    }
  };
  onChangeRepeat = (name, value) => {
    const classes = value ? '' : 'd-none';
    const formModel = this.state.formModel.map(input => {
      return input.name === 'repeatFrequency'
        ? { ...input, classes: classes }
        : input;
    });
    this.setState({
      ...this.state,
      formModel,
      event: {
        ...this.state.event,
        [name]: value
      }
    });
  };
  onChageStartAt = (name, value) => {
    const startAt = new Date(value);
    const startTime =
      startAt.getHours() * 60 * 60 +
      startAt.getMinutes() * 60 +
      startAt.getSeconds();
    this.setState({
      ...this.state,
      event: {
        ...this.state.event,
        [name]: value,
        startTime
      }
    });
  };
  onSubmit = e => {
    e.preventDefault();
    const validation = validateForm(
      this.state.event,
      this.state.validationModel
    );

    this.setState({
      ...this.state,
      errors: validation.errors
    });
    if (this.props.id && !validation.isError) {
      console.log('update', JSON.stringify(this.state.event));
      this.props.updatePlan(this.state.event);
      this.props.history.push('/my-lists');
    } else {
      if (!validation.isError) {
        //console.log(JSON.stringify(this.state.event));
        this.props.addPlan(this.state.event);
        this.props.history.push('/my-lists');
      }
    }
  };
  handleBlur = e => {
    const fieldErrors = validateField(
      e.target.name,
      e.target.value,
      this.state.validationModel
    );
    if (fieldErrors) {
      this.setState({
        ...this.state,
        errors: { ...this.state.errors, [e.target.name]: fieldErrors }
      });
    }
  };
  render() {
    return (
      <>
        <div className="row">
          <div className="card-heading col-0 col-sm-6"></div>
          <div className="col-12 col-sm-6 p-4">
            <h2 className="title text-capitalize">{this.state.event.type}</h2>
            <GeneralForm
              formModel={this.state.formModel}
              onSubmit={this.onSubmit}
              onChange={this.onChange}
              handleBlur={this.handleBlur}
              event={this.state.event}
              errors={this.state.errors}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(
  connect(mapStateToProps, { addPlan, getPlan, updatePlan })(PlanForm)
);
