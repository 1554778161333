import React, { Component } from 'react';
import { connect } from 'react-redux';

export class Profile extends Component {
  state = {
    user: this.props.auth.user
  };
  render() {
    const { username, displayName, firstName, lastName } = this.state.user;
    return (
      <div className="row">
        <div className="col">
          <div className="m-3">
            <h6>User data:</h6>
            <ul>
              <li>username: {username}</li>
              <li>display: {displayName}</li>
              <li>first name: {firstName}</li>
              <li>last name: {lastName}</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(Profile);
