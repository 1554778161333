import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { getPlan } from '../../actions/plans';

export class ViewPlan extends Component {
  state = {
    plan: {
      id: null,
      type: '',
      title: '',
      startAt: {
        date: new Date()
      },
      description: '',
      organizer: {
        id: null,
        displayName: ''
      }
    }
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({
      ...this.state,
      plan: { ...this.state.plan, id }
    });
    this.getRemotePlan(id);
  }
  getRemotePlan = async id => {
    const plan = await this.props.getPlan(id);
    this.setState({
      ...this.state,
      plan
    });
  };
  getFormattedDate = (date, options) => {
    return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
  };
  render() {
    const {
      id,
      type,
      title,
      description,
      startAt: { date: startAt },
      organizer: { id: organizerId, displayName }
    } = this.state.plan;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="text-center m-2 p-2 text-capitalize">
              <h1>{title}</h1>
              <div>{description}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6"></div>
          <div className="col-12 col-md-6">
            <div className="float-right">
              <Link to={`/plans/edit/${this.props.match.params.id}`}>
                <i className="fas fa-edit fa-lg"></i>
              </Link>
            </div>
            <div className="m-1 p-2">
              <div>
                <span>Type: </span>
                <span>{type}</span>
              </div>
              <div>
                <span>Start at: </span>
                <span>
                  {this.getFormattedDate(startAt, {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                  })}
                </span>
              </div>
              <div>
                <span>Organizer: </span>
                <span>
                  {this.props.auth.user.id === organizerId
                    ? 'you'
                    : displayName}
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps, { getPlan })(ViewPlan));
