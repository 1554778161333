import React, { Component } from 'react';
import './css/DurationHelper.css';

export class DurationHelper extends Component {
  state = {
    seconds: 0,
    h: 0,
    m: 0
  };
  componentDidMount() {
    const { h, m } = this.sec2time(this.props.value);
    this.setState({
      ...this.state,
      seconds: this.props.value,
      h,
      m
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      const { h, m } = this.sec2time(this.props.value);
      this.setState({
        ...this.state,
        seconds: this.props.value,
        h,
        m
      });
    }
  }
  handleChange = e => {
    const name = e.target.name;
    let value = e.target.value;
    if (e.target.value === '') {
      value = 0;
    }
    let seconds = 0;
    if (name === 'h') {
      seconds = value * 60 * 60 + this.state.m * 60;
    } else if (name === 'm') {
      seconds = this.state.h * 60 * 60 + value * 60;
    }
    this.props.onChange(seconds);
    this.setState({
      ...this.state,
      [name]: parseInt(value)
    });
  };
  sec2time = timeInSeconds => {
    let time = parseFloat(timeInSeconds).toFixed(3);
    let hours = Math.floor(time / 60 / 60);
    let minutes = Math.floor(time / 60) % 60;
    return { h: hours, m: minutes };
  };
  pad = (num, size) => {
    return ('000' + num).slice(size * -1);
  };
  render() {
    return (
      <div className="row">
        <div className="col-6">
          <input
            className="form__field"
            type="number"
            name="h"
            value={this.state.h === 0 ? '' : this.state.h}
            onChange={this.handleChange}
          ></input>
          hours
        </div>
        <div className="col-6">
          <input
            className="form__field"
            type="number"
            name="m"
            value={this.state.m === 0 ? '' : this.state.m}
            onChange={this.handleChange}
          ></input>
          minutes
        </div>
      </div>
    );
  }
}

export default DurationHelper;
