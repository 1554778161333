import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';

export class Login extends Component {
  state = {
    username: '',
    password: ''
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password);
  };

  onChange = e =>
    this.setState({
      [e.target.name]: e.target.value
    });

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }
    const { username, password } = this.state;
    return (
      <div className="container-login text-light pt-5">
        <div className="col-md-6 col-lg-5 col-xl-4 m-auto d-flex">
          <div className=" card-body mt-2">
            <span>
              <h6 className="text-small float-right">beta</h6>
              <h2 className="text-center">Sign In</h2>
            </span>
            <div className="pt-4">
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="username"
                    className="form-control"
                    name="username"
                    onChange={this.onChange}
                    value={username}
                  />
                </div>
                <div className="form-group pt-2">
                  <input
                    type="password"
                    placeholder="password"
                    className="form-control"
                    name="password"
                    onChange={this.onChange}
                    value={password}
                  />
                </div>
                <div className="form-group pt-3">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg w-100 float-right"
                  >
                    Sign In
                  </button>
                </div>
                <p>
                  {/*
                Don't have an account? <Link to="/register">Register</Link>
                */}
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Login);
