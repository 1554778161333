import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getUsers } from '../../actions/users';
import Title from '../layout/Title';

export class UserList extends Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
    getUsers: PropTypes.func.isRequired
  };

  state = {
    users: []
  };

  componentDidMount() {
    this.props.getUsers();
  }

  render() {
    return (
      <>
        <Title title="users" />
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {this.props.users.map(user => {
              const { id, username, firstName, lastName, email } = user;
              return (
                <tr key={id}>
                  <td>{username}</td>
                  <td>{firstName}</td>
                  <td>{lastName}</td>
                  <td>{email}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users.users
});

export default connect(mapStateToProps, { getUsers })(UserList);
