import { CREATE_MESSAGE, GET_ERRORS, LOGOUT_SUCCESS } from './types';

// CREATE MESSAGE
export const createMessage = msg => {
  return {
    type: CREATE_MESSAGE,
    payload: msg
  };
};

// RETURN ERRORS
export const returnErrors = err => {
  const msg = err.response ? err.response.data : 'Undefined Error';
  const status = err.response ? err.response.status : 500;
  switch (status) {
    case 401:
      return {
        type: LOGOUT_SUCCESS
      };
    default:
      return {
        type: GET_ERRORS,
        payload: { msg, status }
      };
  }
};
