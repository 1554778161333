import React, { Component } from 'react';

import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import './css/Navigation.css';
import { Link } from 'react-router-dom';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import UserList from '../../components/users/UserList';
import Dashboard from '../../components/layout/Dashboard';
import MyLists from '../layout/MyLists';
import Search from '../../components/search/Search';
import Create from '../../components/layout/Create';
import CreateEvent from '../../components/plans/CreateEvent';
import CreateTask from '../../components/plans/CreateTask';
import EditEvent from '../plans/EditEvent';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from './MenuList';
import Profile from '../settings/Profile';
import NotFound from './default/NotFound';
import ViewPlan from '../plans/ViewPlan';

class Navigation extends Component {
  state = {
    user: {
      displayName: ''
    },
    menuClose: true,
    menuLinks: [
      {
        title: 'search',
        to: '/events/search',
        iconClass: 'fas fa-search fa-2x'
      },
      {
        title: 'lists',
        to: '/my-lists',
        iconClass: 'fas fa-list fa-2x'
      },
      {
        title: 'users',
        to: '/users',
        iconClass: 'fas fa-user-friends fa-2x'
      },
      {
        title: 'create',
        to: '/create',
        iconClass: 'fas fa-plus fa-2x'
      }
    ]
  };
  componentDidMount() {
    const displayName = this.props.auth.user
      ? this.props.auth.user.displayName
      : '';
    this.setState({
      user: {
        ...this.state.user,
        displayName
      }
    });
  }
  handleMenu = () => {
    this.setState({
      ...this.state,
      menuClose: !this.state.menuClose
    });
  };
  handleCloseMenu = () => {
    this.setState({
      ...this.state,
      menuClose: true
    });
  };
  render() {
    return (
      <>
        <ClickAwayListener onClickAway={this.handleCloseMenu}>
          <nav className="navbar navbar-expand-md main-navigation sticky-top">
            <div className="menu-h-close navbar-toggler collapsed">
              <input
                type="checkbox"
                checked={!this.state.menuClose}
                onChange={this.handleMenu}
              />

              <span></span>
              <span></span>
              <span></span>

              <ul
                className="menu-h-list scroll-style-body"
                style={{
                  height: window.document.body.offsetHeight + 'px',
                  maxHeight: '580px'
                }}
              >
                <MenuList
                  user={this.state.user}
                  menuLinks={this.state.menuLinks}
                  handleCloseMenu={this.handleCloseMenu}
                  logout={this.props.logout}
                  showTitles={true}
                />
              </ul>
            </div>
            <Link to="/" className="navbar-brand">
              <img
                src="img/syncplanner-logo.svg"
                height="20"
                alt="syncplanner"
              />
            </Link>
            <div className="container mw-100"></div>
            <div
              className="navbar-buttons-container navbar-collapse collapse text-allign-center"
              id="navbarToggler"
            >
              <ul className="navbar-nav">
                <MenuList
                  user={this.state.user}
                  menuLinks={this.state.menuLinks}
                  handleCloseMenu={this.handleCloseMenu}
                  logout={this.props.logout}
                  showTitles={false}
                />
              </ul>
            </div>
          </nav>
        </ClickAwayListener>
        <div>
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/events/search" component={Search} />
            <Route exact path="/my-lists" component={MyLists} />
            <Route exact path="/create" component={Create} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/events/create" component={CreateEvent} />
            <Route exact path="/tasks/create" component={CreateTask} />
            <Route exact path="/plans/edit/:id" component={EditEvent} />
            <Route exact path="/plans/view/:id" component={ViewPlan} />
            <Route exact path="/users" component={UserList} />
            <Route path="/" component={NotFound} />
          </Switch>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logout })(Navigation);
