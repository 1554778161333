import { createMessage, returnErrors } from './messages';
import { get, deletion, post, put } from '../helpers/Call';
import { graphql } from '../helpers/Call';

import {
  GET_MY_PLANS,
  GET_PLAN,
  ADD_PLAN,
  UPDATE_PLAN,
  SUBSCRIBE_EVENT,
  DELETE_EVENT,
  UPDATE_RUNNING_PLANS
} from './types';

export const getMyPlans = (from, userId) => async dispatch => {
  const query = `query{
    events(maxResult:500,
      filter:{
      groups:[{
        conditionsLogic: OR
        conditions:{
          startAt:{
            greater:{
              value:"${from.toISOString()}"
            }
          },
          repeat: {
            equal: {
              value: true
            }
          }
        }
      },
      {
        groupLogic: AND
        conditions: {
          organizer: {
            equal: {
              value: ${userId}
            }
          }
        }
      }]
    }, sorting:{
      field: startAt,
      order: ASC
    }) {
      id
      type
      title
      category
      description
      startAt
      startTime
      duration
      weekday
      isRepeat
      repeatFrequency
    }
  }`;
  const myPlans = await graphql(query)
    .then(res => {
      dispatch({
        type: GET_MY_PLANS,
        payload: res.data.data.events
      });

      return res.data.data.events;
    })
    .catch(err => dispatch(returnErrors(err)));
  return myPlans;
};

// GET PLAN
export const getPlan = id => async dispatch => {
  const plan = await get(`${process.env.REACT_APP_API_URL}/api/events/${id}`)
    .then(res => {
      dispatch({
        type: GET_PLAN,
        payload: res.data
      });
      return res.data;
    })
    .catch(err => {
      dispatch(returnErrors(err));
    });
  return plan;
};

// DELETE EVENT
export const deleteEvent = id => dispatch => {
  deletion(`${process.env.REACT_APP_API_URL}/api/events/${id}`)
    .then(res => {
      dispatch(createMessage({ success: 'Event Deleted' }));
      dispatch({
        type: DELETE_EVENT,
        payload: id
      });
    })
    .catch(err =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

// ADD PLAN
export const addPlan = plan => dispatch => {
  post(`${process.env.REACT_APP_API_URL}/api/events/`, JSON.stringify(plan))
    .then(res => {
      dispatch(createMessage({ success: `${plan.type} added` }));
      dispatch({
        type: ADD_PLAN,
        payload: res.data
      });
    })
    .catch(err => dispatch(returnErrors(err)));
};

// UPDATE PLAN
export const updatePlan = plan => dispatch => {
  put(`${process.env.REACT_APP_API_URL}/api/events/${plan.id}`, plan)
    .then(res => {
      dispatch(createMessage({ success: `${plan.type} updated` }));
      dispatch({
        type: UPDATE_PLAN,
        payload: res.data
      });
    })
    .catch(err => dispatch(returnErrors(err)));
};

// SUBSCRIBE EVENT
export const subscribeEvent = (eventId, userId) => dispatch => {
  put(
    `${process.env.REACT_APP_API_URL}/api/events/subscribe/${eventId}`,
    userId
  )
    .then(res => {
      dispatch(createMessage({ success: 'Event Updated' }));
      dispatch({
        type: SUBSCRIBE_EVENT,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch(returnErrors(err));
    });
};

export const updateRunningPlans = runningPlans => dispatch => {
  dispatch({
    type: UPDATE_RUNNING_PLANS,
    payload: runningPlans
  });
};
