import React, { Component } from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import PrivateRoute from '../../components/common/PrivateRoute';
import Navigation from '../../components/layout/Navigation';
import Login from '../../components/accounts/Login';
import Register from '../../components/accounts/Register';

export class AppRouter extends Component {
  render() {
    return (
      <>
        <Route exact path="/register" component={Register} />
        <Route exact path="/login" component={Login} />
        <PrivateRoute path="/" component={Navigation} load={true} />
      </>
    );
  }
}

export default Router;
