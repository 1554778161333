import {
  GET_MY_PLANS,
  GET_PLAN,
  DELETE_EVENT,
  ADD_PLAN,
  UPDATE_PLAN,
  SUBSCRIBE_EVENT,
  UPDATE_RUNNING_PLANS
} from '../actions/types';

const initialState = {
  myPlans: [],
  runningPlans: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_MY_PLANS:
      return {
        ...state,
        myPlans: action.payload
      };
    case ADD_PLAN:
      return {
        ...state,
        myPlans: [...state.myPlans, action.payload]
      };
    case GET_PLAN:
      return {
        ...state
      };
    case UPDATE_PLAN:
      const myPlans = state.myPlans.map(plan => {
        return action.payload.id === plan.id ? action.payload : plan;
      });
      return {
        ...state,
        myPlans: myPlans
      };
    case SUBSCRIBE_EVENT:
      return {
        ...state,
        myPlans: state.myPlans.map(plan => {
          return action.payload.id === plan.id ? action.payload : plan;
        })
      };
    case DELETE_EVENT:
      return {
        ...state,
        myPlans: state.myPlans.filter(plan => plan.id !== action.payload)
      };
    case UPDATE_RUNNING_PLANS:
      return {
        ...state,
        runningPlans: action.payload
      };
    default:
      return state;
  }
}
