import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

const useStyles = theme => ({
  root: {
    width: '100%',
    color: '#ccc'
  },
  inline: {
    display: 'inline',
    color: '#666'
  },
  dividerColor: {
    backgroundColor: '#333',
    marginBottom: '1px'
  }
});

export class EventList extends Component {
  initialState = {
    publicEvents: []
  };
  componentDidMount() {
    this.setState({
      ...this.initialState,
      publicEvents: this.props.publicEvents
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.publicEvents !== prevProps.publicEvents) {
      this.setState({ ...this.state, publicEvents: this.props.publicEvents });
    }
  }
  render() {
    const { classes, publicEvents } = this.props;
    return (
      <List className={classes.root}>
        {publicEvents.map(event => {
          return (
            <div key={event.id}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    variant="square"
                    alt={event.title}
                    src={`${window.location.origin}/img/categories/${event.category}/large.jpg`}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <strong className="text-capitalize">{event.title}</strong>
                  }
                  secondary={
                    <>
                      <span className={classes.inline}>
                        {event.description} &nbsp;
                      </span>
                    </>
                  }
                />
              </ListItem>
              <Divider
                variant="inset"
                component="div"
                classes={{ root: classes.dividerColor }}
              />
            </div>
          );
        })}
      </List>
    );
  }
}

export default withStyles(useStyles)(EventList);
