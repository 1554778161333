import React, { Component, Fragment } from 'react';
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export class Alerts extends Component {
  static propTypes = {
    error: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired
  };
  componentDidUpdate(prevProps) {
    const { error, alert, message } = this.props;
    if (error !== prevProps.error) {
      error.msg
        ? this.renderError(alert, error.status, error.msg)
        : alert.error('Unkown Error');
    }
    if (message !== prevProps.message) {
      if (message.error) alert.error(message.error);
      if (message.success) alert.success(message.success);
    }
  }
  renderError(alert, code, msg) {
    switch (code) {
      case 500:
        alert.error(msg.detail);
        break;
      case 404:
        alert.error('Endpoint not found');
        break;
      case 400:
        alert.error(msg.error_description);
        break;
      default:
        alert.error('Unknown error');
        break;
    }
  }

  render() {
    return <Fragment />;
  }
}

const mapStateToProps = state => ({
  error: state.errors,
  message: state.messages
});

export default connect(mapStateToProps)(withAlert()(Alerts));
