import React, { Component } from 'react';
import Form from './PlanForm';

export class CreateEvent extends Component {
  render() {
    return (
      <>
        <Form type="event" />
      </>
    );
  }
}

export default CreateEvent;
