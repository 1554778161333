import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Checkbox,
  Switch,
  Radio,
  FormControlLabel
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
//import { DatePicker, TimePicker, DateTimePicker } from '@material-ui/pickers';
import './css/Form.css';
import './css/Switch.css';
import DurationHelper from './DurationHelper';

import { green } from '@material-ui/core/colors';

const useStyles = theme => ({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600]
    }
  },
  checked: {}
});

export class InputSelector extends Component {
  state = {
    inputProps: {
      style: {
        color: 'white'
      }
    },
    inputLabelProps: {
      style: {
        color: 'white'
      }
    }
  };
  handleChangeDuration = duration => {
    const { hours, minutes, seconds } = duration;
    const durationInSeconds = hours * 60 * 60 + minutes * 60 + seconds;
    return durationInSeconds;
  };
  getTimezoneString = () => {
    const d = new Date();
    const dtf = Intl.DateTimeFormat(undefined, { timeZoneName: 'long' });
    const result = dtf
      .formatToParts(d)
      .find(part => part.type == 'timeZoneName').value;
    return result;
  };
  render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.props.onSubmit} autoComplete="off">
        {this.props.formModel.map(input => {
          switch (input.inputType) {
            case 'text':
              return (
                <div
                  key={input.name}
                  className={`input-field ${input.classes}`}
                >
                  <div className="input-group">
                    <TextField
                      color={'secondary'}
                      label={input.label ? input.label : input.name}
                      name={input.name}
                      onChange={e => {
                        this.props.onChange(e.target.name, e.target.value);
                      }}
                      onBlur={this.props.handleBlur}
                      value={this.props.event[input.name]}
                      fullWidth
                      InputProps={this.state.inputProps}
                      InputLabelProps={this.state.inputLabelProps}
                    />
                  </div>
                  {this.props.errors[input.name] &&
                    this.props.errors[input.name].map(error => {
                      return (
                        <FormHelperText key={error} style={{ color: 'red' }}>
                          {error}
                        </FormHelperText>
                      );
                    })}
                </div>
              );
            case 'dateTime':
              return (
                <div
                  key={input.name}
                  className={`input-field ${input.classes}`}
                >
                  <div className="input-group">
                    <DateTimePicker
                      color={'secondary'}
                      //disableToolbar
                      //variant="inline"
                      format="dd/MM/yyyy HH:mm a"
                      label={input.label}
                      name={input.name}
                      fullWidth
                      value={this.props.event[input.name]}
                      onChange={date =>
                        this.props.onChange(input.name, date.getTime())
                      }
                      onBlur={this.props.handleBlur}
                      InputProps={this.state.inputProps}
                      InputLabelProps={this.state.inputLabelProps}
                    />
                    <i className="far fa-calendar-alt zmdi zmdi-calendar-note input-icon js-btn-calendar mr-5"></i>
                  </div>
                  <span className="text-primary">
                    {this.getTimezoneString()}
                  </span>
                  {this.props.errors[input.name] &&
                    this.props.errors[input.name].map(error => {
                      return (
                        <FormHelperText key={error} style={{ color: 'red' }}>
                          {error}
                        </FormHelperText>
                      );
                    })}
                </div>
              );
            case 'select':
              return (
                <div
                  key={input.name}
                  className={`input-field ${input.classes}`}
                >
                  <FormControl
                    classes={{
                      root: 'input-group'
                    }}
                  >
                    <InputLabel style={this.state.inputLabelProps.style}>
                      {input.label}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name={input.name}
                      value={this.props.event[input.name]}
                      onChange={e => {
                        this.props.onChange(e.target.name, e.target.value);
                      }}
                      onBlur={this.props.handleBlur}
                      fullWidth
                      style={this.state.inputProps.style}
                      required={true}
                    >
                      {input.options
                        ? input.options.map(option => {
                            return (
                              <MenuItem key={option.value} value={option.value}>
                                {option.name}
                              </MenuItem>
                            );
                          })
                        : ''}
                    </Select>
                  </FormControl>
                  {this.props.errors[input.name] &&
                    this.props.errors[input.name].map(error => {
                      return (
                        <FormHelperText key={error} style={{ color: 'red' }}>
                          {error}
                        </FormHelperText>
                      );
                    })}
                </div>
              );
            case 'price':
              return (
                <div
                  key={input.name}
                  className={`input-field ${input.classes}`}
                >
                  <div className="input-group">
                    <TextField
                      fullWidth={true}
                      type="number"
                      label={input.label}
                      name={input.name}
                      value={
                        this.props.event[input.name] === 0
                          ? ''
                          : this.props.event[input.name]
                      }
                      onChange={e => {
                        this.props.onChange(e.target.name, e.target.value);
                      }}
                      onBlur={this.props.handleBlur}
                      InputLabelProps={this.state.inputLabelProps}
                      InputProps={this.state.inputProps}
                    />
                    <i className="fas fa-euro-sign zmdi zmdi-calendar-note input-icon js-btn-calendar mr-5"></i>
                  </div>
                  {this.props.errors[input.name] &&
                    this.props.errors[input.name].map(error => {
                      return (
                        <FormHelperText key={error} style={{ color: 'red' }}>
                          {error}
                        </FormHelperText>
                      );
                    })}
                </div>
              );
            case 'duration':
              return (
                <div key={input.name} className="input-field">
                  <div className="">
                    <InputLabel style={this.state.inputLabelProps.style}>
                      {input.label}
                    </InputLabel>
                    <DurationHelper
                      value={this.props.event[input.name]}
                      onChange={duration => {
                        this.props.onChange(input.name, duration);
                      }}
                    />
                  </div>
                  {this.props.errors[input.name] &&
                    this.props.errors[input.name].map(error => {
                      return (
                        <FormHelperText key={error} style={{ color: 'red' }}>
                          {error}
                        </FormHelperText>
                      );
                    })}
                </div>
              );
            case 'number':
              return (
                <div
                  key={input.name}
                  className={`input-field ${input.classes}`}
                >
                  <div className="input-group">
                    <TextField
                      fullWidth={true}
                      type="number"
                      label={input.label}
                      name={input.name}
                      value={
                        this.props.event[input.name] === 0
                          ? ''
                          : this.props.event[input.name]
                      }
                      onChange={e => {
                        this.props.onChange(e.target.name, e.target.value);
                      }}
                      onBlur={this.props.handleBlur}
                      InputLabelProps={this.state.inputLabelProps}
                      InputProps={this.state.inputProps}
                    />

                    <span
                      style={{
                        position: 'absolute',
                        bottom: '-1px',
                        right: '40px'
                      }}
                    >
                      <Select
                        name={input.name}
                        value={'minutes'}
                        onChange={this.state.handleChangeDuration}
                        style={this.state.inputProps.style}
                        required={true}
                      >
                        <MenuItem value={'minutes'}>minutes</MenuItem>
                        <MenuItem value={'hours'}>hours</MenuItem>
                      </Select>
                    </span>
                  </div>
                  {this.props.errors[input.name] &&
                    this.props.errors[input.name].map(error => {
                      return (
                        <FormHelperText key={error} style={{ color: 'red' }}>
                          {error}
                        </FormHelperText>
                      );
                    })}
                </div>
              );
            case 'checkbox':
              return (
                <div
                  key={input.name}
                  className={`input-field ${input.classes}`}
                >
                  <div className="">
                    <Checkbox
                      name={input.name}
                      checked={this.props.event[input.name]}
                      onChange={e => {
                        this.props.onChange(e.target.name, e.target.checked);
                      }}
                      classes={{
                        root: 'input-checkbox'
                      }}
                      color="primary"
                      inputProps={{
                        'aria-label': 'secondary checkbox',
                        color: '#fff !important'
                      }}
                    />
                    {input.label}
                  </div>
                  {this.props.errors[input.name] &&
                    this.props.errors[input.name].map(error => {
                      return (
                        <FormHelperText key={error} style={{ color: 'red' }}>
                          {error}
                        </FormHelperText>
                      );
                    })}
                </div>
              );
            case 'switch':
              return (
                <div
                  key={input.name}
                  className={`input-field ${input.classes}`}
                >
                  <div className="">
                    <Switch
                      color="primary"
                      focusVisibleClassName={'focus-visible'}
                      name={input.name}
                      checked={this.props.event[input.name]}
                      onChange={e => {
                        this.props.onChange(e.target.name, e.target.checked);
                      }}
                      disableRipple
                      classes={{
                        root: 'custom-switch',
                        switchBase: 'custom-switch-base',
                        thumb: 'custom-switch-thumb',
                        track: 'custom-switch-track',
                        checked: 'custom-switch-checked'
                      }}
                    />
                    <strong>
                      {this.props.event[input.name]
                        ? input.options.checked
                        : input.options.unchecked}
                    </strong>
                  </div>
                  {this.props.errors[input.name] &&
                    this.props.errors[input.name].map(error => {
                      return (
                        <FormHelperText key={error} style={{ color: 'red' }}>
                          {error}
                        </FormHelperText>
                      );
                    })}
                </div>
              );
            case 'radio':
              return (
                <div
                  key={input.name}
                  className={`input-field ${input.classes}`}
                >
                  <div className="">
                    {input.options.map(option => {
                      return (
                        <FormControlLabel
                          key={option.value}
                          control={
                            <Radio
                              value={option.value}
                              name={input.name}
                              inputProps={{ 'aria-label': option.name }}
                              checked={
                                this.props.event[input.name] === option.value
                              }
                              onChange={e => {
                                this.props.onChange(
                                  e.target.name,
                                  e.target.value
                                );
                              }}
                              disableRipple
                              classes={{
                                root: 'custom-switch',
                                checked: 'custom-switch-checked'
                              }}
                            />
                          }
                          label={option.name}
                        />
                      );
                    })}
                  </div>
                  {this.props.errors[input.name] &&
                    this.props.errors[input.name].map(error => {
                      return (
                        <FormHelperText key={error} style={{ color: 'red' }}>
                          {error}
                        </FormHelperText>
                      );
                    })}
                </div>
              );
            default:
              return (
                <span key={input.name}>
                  <TextField
                    key={input.name}
                    type="hidden"
                    name={input.name}
                    value={this.props.event[input.name]}
                    fullWidth
                    InputProps={this.state.inputProps}
                    InputLabelProps={this.state.inputLabelProps}
                  />
                  {this.props.errors[input.name] &&
                    this.props.errors[input.name].map(error => {
                      return (
                        <FormHelperText key={error} style={{ color: 'red' }}>
                          {error}
                        </FormHelperText>
                      );
                    })}
                </span>
              );
          }
        })}
        <div className="pt-3 mb-3">
          <button className="btn btn-lg btn-primary float-right" type="submit">
            Submit
          </button>
        </div>
      </form>
    );
  }
}

export default withStyles(useStyles)(InputSelector);
