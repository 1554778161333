export const validateForm = (form, formModel) => {
  const keys = Object.keys(form);
  const errors = {};
  let isError = false;

  keys.map(field => {
    const fieldErrors = validateField(field, form[field], formModel);
    if (fieldErrors && fieldErrors.length > 0) {
      errors[field] = fieldErrors;
      isError = true;
    }
  });
  return { isError, errors };
};

export const validateField = (fieldName, fieldValue, formModel) => {
  const field = formModel.find(field => {
    return field.name === fieldName;
  });

  if (field) {
    let fieldErrors = [];
    if (field.required) {
      const error = isRequired(fieldValue);
      if (error) {
        fieldErrors.push(error);
      }
    }
    if (field.validators) {
      field.validators.map(validator => {
        const error = validate(fieldName, fieldValue, validator);
        if (error) {
          fieldErrors.push(error);
        }
      });
    }
    return fieldErrors;
  }
  return false;
};

export const isRequired = fieldValue => {
  if (!fieldValue) {
    return 'This field is required';
  } else if (fieldValue.length === 0) {
    return 'This field is required';
  }
  return false;
};

export const validate = (fieldName, fieldValue, validator) => {
  switch (validator.name) {
    case 'NotEmpty':
      if (!fieldValue) {
        return 'This field cannot be empty';
      } else if (fieldValue.length === 0) {
        return 'This field cannot be empty';
      }
      return false;
    default:
      return false;
  }
};
