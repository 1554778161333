export const GET_ERRORS = 'GET_ERRORS';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const GET_USERS = 'GET_USERS';
export const ADD_USER = 'ADD_USER';
//export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

export const GET_MY_PLANS = 'GET_MY_PLANS';
export const GET_PLAN = 'GET_PLAN';
export const ADD_PLAN = 'ADD_PLAN';
export const UPDATE_RUNNING_PLANS = 'UPDATE_RUNNING_PLANS';
export const UPDATE_PLAN = 'UPDATE_PLAN';
export const SUBSCRIBE_EVENT = 'SUBSCRIBE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
