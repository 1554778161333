import React, { Component } from 'react';
import TableCard from './TableCard';

export class Create extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-6 text-center">
          <TableCard
            text={'Event'}
            link={'/events/create'}
            info={'Events are linked to a timezone'}
          />
        </div>
        <div className="col-6 text-center">
          <TableCard
            text={'task'}
            link={'/tasks/create'}
            info={'Tasks are not linked to a timezone'}
          />
        </div>
      </div>
    );
  }
}

export default Create;
