import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getMyPlans, updateRunningPlans } from '../../actions/plans';
import './css/TaskList.css';
import BlockItem from './BlockItem';

export class TaskList extends Component {
  _isMounted = false;
  NUMBEROFBLOCKS = 12;
  state = {
    blocks: [],
    myPlans: [],
    //now: new Date('2020-04-20T13:59:50')
    now: new Date()
  };
  componentDidMount() {
    this._isMounted = true;
    this.runTime();
    this.generateHours(this.state.now, this.state.blocks, this.NUMBEROFBLOCKS);
    this.getRemoteTasks();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  runTime = () => {
    const time = this.state.time === 86400 ? 0 : this.state.time + 1;
    this.state.now.setSeconds(this.state.now.getSeconds() + 1);
    if (this._isMounted) {
      this.setState({ ...this.state, time });
      setTimeout(this.runTime, 1000);
    }
  };
  getTime = now => {
    const h = now.getHours();
    const m = now.getMinutes();
    const s = now.getSeconds();
    const time = h * 60 * 60 + m * 60 + s;
    return time;
  };
  getRemoteTasks = async () => {
    const from = new Date();
    from.setDate(from.getDate() - 1);
    if (this.props.user) {
      const myPlans = await this.props.getMyPlans(from, this.props.user.id);
      this.setState({
        ...this.state,
        myPlans
      });
    }
  };
  generateHours = (startDate, blocks, numberOfBlocks) => {
    let date = startDate;
    for (let i = 1; i <= numberOfBlocks; i++) {
      const isRunning =
        this.state.now.getHours() === date.getHours() &&
        this.state.now.getDay() === date.getDay()
          ? true
          : false;
      const startTime = date.getHours() * 60 * 60;
      const block = {
        id:
          date.getYear() +
          '-' +
          date.getMonth() +
          '-' +
          date.getDate() +
          '-' +
          date.getHours(),
        title: 'Title',
        description: 'description',
        date: date,
        startTime: startTime,
        endTime: startTime + 3600,
        running: isRunning
      };
      if (blocks.length < 168) {
        blocks.push(block);
        date = this.addHourToDate(date, 1);
      }
    }
    return blocks;
  };
  addHourToDate = (date, h) => {
    const newHour = new Date(date.getTime() + h * 60 * 60 * 1000);
    return newHour;
  };
  handleScroll = e => {
    let element = e.target;
    if (
      element.scrollHeight - parseInt(element.scrollTop) ===
      element.clientHeight
    ) {
      // do something at end of scroll
      this.appendBlocksToExistingList();
    }
  };
  appendBlocksToExistingList = () => {
    const lastBlock = this.state.blocks[this.state.blocks.length - 1];
    const nextDate = new Date(lastBlock.date.getTime() + 1 * 60 * 60 * 1000);
    const blocks = this.generateHours(
      nextDate,
      this.state.blocks,
      this.NUMBEROFBLOCKS
    );
    this.setState({
      ...this.state,
      blocks
    });
  };
  moveToNextBlock = () => {
    const blocks = this.generateHours(this.state.now, [], this.NUMBEROFBLOCKS);
    this.setState({
      ...this.state,
      blocks
    });
    /*
    this.ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end'
    });
    */
  };
  render() {
    const blocksHeight =
      this.props.runningPlans.length > 0
        ? 302
        : document.documentElement.clientHeight - 64;
    return (
      <div
        className="task-list-container scrollbar scroll-style"
        onScroll={this.handleScroll}
        style={{ height: blocksHeight + 'px' }}
      >
        <div className="p-0 force-overflow text-light">
          <ul className="task-list">
            {this.state.blocks.map((block, index) => {
              return (
                <div id="bg-light" key={index}>
                  <BlockItem
                    block={block}
                    moveToNextBlock={this.moveToNextBlock}
                    plans={this.state.myPlans}
                    now={this.state.now}
                    updateRunningPlans={this.props.updateRunningPlans}
                    isMounted={this._isMounted}
                  />
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tasks: state.plans.myPlans,
  user: state.auth.user,
  myPlans: state.plans.myPlans,
  runningPlans: state.plans.runningPlans
});

export default connect(mapStateToProps, {
  updateRunningPlans,
  getMyPlans
})(TaskList);
