import React, { Component } from 'react';
import './css/EventCard.css';
import { Link } from 'react-router-dom';

export class EventCard extends Component {
  state = {
    lang: 'en-US',
    event: {
      title: '',
      description: '',
      startAt: { date: null },
      endAt: null,
      thumbnail: ''
    },
    loading: true
  };
  componentDidMount() {
    this.setState({ ...this.state, event: this.props.event, loading: false });
  }
  getFormattedDate = (date, options) => {
    return new Intl.DateTimeFormat(this.state.lang, options).format(
      new Date(date)
    );
  };
  getWhenPlan = (startAt, startTime, isRepeat, repeatFrequency) => {
    let when = '';
    if (isRepeat) {
      switch (repeatFrequency) {
        case 'daily':
          when = 'Every day at ';
          break;
        case 'weekly':
          const weekday = this.getFormattedDate(startAt, {
            weekday: 'long'
          });
          when = `Every ${weekday} at`;
          break;
        case 'monthly':
          const day = this.getFormattedDate(startAt, {
            day: 'numeric'
          });
          when = `Every month the ${day} at`;
          break;
        case 'yearly':
          const date = this.getFormattedDate(startAt, {
            day: 'numeric',
            month: 'short'
          });
          when = `Every year on ${date} at`;
          break;
        default:
          when = 'At';
          break;
      }
      return when;
    } else {
      when = startAt
        ? this.getFormattedDate(startAt, {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          })
        : '';
    }
    return `${when} at `;
  };
  getTimePlan = (type, startAt, startTime) => {
    switch (type) {
      case 'event':
        return this.getFormattedDate(startAt, {
          hour: '2-digit',
          minute: '2-digit'
        });
      case 'task':
        return this.sec2time(startTime);
      default:
        return this.getFormattedDate(startAt, {
          hour: '2-digit',
          minute: '2-digit'
        });
    }
  };
  sec2time = timeInSeconds => {
    let time = parseFloat(timeInSeconds).toFixed(3);
    let hours = Math.floor(time / 60 / 60);
    let minutes = Math.floor(time / 60) % 60;
    let seconds = Math.floor(time - minutes * 60);
    return this.state.lang === 'en-US' || this.state.lang === 'en-GB'
      ? this.tConvert(`${this.pad(hours, 2)}:${this.pad(minutes, 2)}`)
      : `${this.pad(hours, 2)}:${this.pad(minutes, 2)}`;
  };
  pad = (num, size) => {
    return ('000' + num).slice(size * -1);
  };
  tConvert = time => {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  };
  render() {
    const {
      id,
      type,
      title,
      description,
      category,
      startAt,
      startTime,
      isRepeat,
      repeatFrequency
    } = this.state.event;

    return this.state.loading ? (
      ''
    ) : (
      <Link to={`/plans/view/${id}`}>
        <li className="event-card force-overflow text-light">
          <div className="card mr-3">
            <img
              className="card-img-top"
              style={{ height: '50%' }}
              src={
                category
                  ? `${window.location.origin}/img/categories/${category}/large.jpg`
                  : `${window.location.origin}/img/placeholder-image.png`
              }
              alt={category}
            />
            <div className="card-body" style={{ height: '50%' }}>
              <div className="event-title">
                <span title={title}>{title}</span>
              </div>
              <p className="event-description card-text text-truncate">
                {description}
              </p>
              <div className="w-100">
                <h6 className="pull-right font-weight-bold">
                  {this.getWhenPlan(
                    startAt,
                    startTime,
                    isRepeat,
                    repeatFrequency
                  )}
                </h6>
                <h4 className="pull-right">
                  {this.getTimePlan(type, startAt, startTime)}
                </h4>
              </div>
            </div>
          </div>
        </li>
      </Link>
    );
  }
}

export default EventCard;
