import React, { Component } from 'react';
import './css/Search.css';
import EventList from './EventList';
import EventChips from './EventChips';
import { graphql } from '../../helpers/Call';
import { returnErrors } from '../../actions/messages';
import { getEvent } from '../../actions/plans';

export class Search extends Component {
  state = {
    search: {
      search: ''
    },
    publicEvents: [],
    filterTags: []
  };
  componentDidMount() {
    this.getEvents(this.state.filterTags);
  }
  handleSearch = e => {
    e.preventDefault();
    this.getEvents();
  };
  handleChange = (name, value) => {
    this.setState({
      ...this.state,
      search: { ...this.state.search, [name]: value }
    });
  };
  removeFilter = filterToDelete => {
    const filterTags = this.state.filterTags.filter(
      filter => filter !== filterToDelete
    );
    const publicEvents = this.state.publicEvents.filter(event =>
      filterTags.includes(event.category)
    );
    this.setState({
      ...this.state,
      filterTags,
      publicEvents
    });
  };
  getEvents = async () => {
    const from = new Date();
    from.setDate(from.getDate() - 1);
    const query = `query{
      events(filter:{
        groups:{
          conditionsLogic: AND
          conditions:{
            startAt:{
              greater:{
                value:"${from.toISOString()}"
              }
            },
            public: {
              equal: {
                value: true
              }
            },
            title: {
              like:{
                value: "%${this.state.search.search}%"
              }
            }
          }
        }
      }, sorting:{
        field: startAt,
        order: DESC
      }) {
        id
        type
        title
        category
        description
        startAt
        startTime
        duration
        weekday
      }
    }`;
    const publicEvents = await graphql(query)
      .then(res => {
        return res.data.data.events;
      })
      .catch(err => returnErrors(err));
    const filterTags = publicEvents
      .map(event => {
        return event.category;
      })
      .filter((value, index, array) => array.indexOf(value) === index);
    this.setState({
      ...this.state,
      publicEvents,
      filterTags
    });
  };
  render() {
    return (
      <>
        <div className="event-search-header sticky-top">
          <div className="row">
            <div className="col-0 col-md-2 col-lg-3"></div>
            <div className="col-12 col-md-8 col-lg-6">
              <div className="search-container p-3">
                <form className="searchbar" onSubmit={this.handleSearch}>
                  <input
                    className="search-input"
                    type="text"
                    name="search"
                    value={this.state.search.search}
                    onChange={e =>
                      this.handleChange(e.target.name, e.target.value)
                    }
                    placeholder="Search..."
                  />
                  <button className="btn btn-primary search-icon">
                    <i className="fas fa-search fa-lg"></i>
                  </button>
                </form>
              </div>
            </div>
            <div className="col-0 col-md-2 col-lg-3"></div>
          </div>
          <div className="row">
            <div className="col">
              <EventChips
                filterTags={this.state.filterTags}
                removeFilter={this.removeFilter}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="search-event-category-container">
              <h1 className="ml-3 text-light">Events</h1>
              <EventList publicEvents={this.state.publicEvents} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Search;
