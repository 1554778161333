import React, { Component } from 'react';

export class Loading extends Component {
  render() {
    return (
      <div className="row" style={{ height: this.props.height }}>
        <div className="col-3 mx-auto my-auto">
          <div className="text-center">
            <i className="fas fa-spinner fa-pulse fa-3x"></i>
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;
