import React, { Component } from 'react';
import './css/TaskSticker.css';

export class TaskSticker extends Component {
  getTopRem = () => {
    const startBlockSeconds =
      new Date(this.props.block.date).getHours() * 60 * 60;

    if (this.props.task.startTime < startBlockSeconds) {
      const startRem = 0;
      return startRem + 'rem';
    } else {
      const startSeconds = this.props.task.startTime % 3600;
      const startRem = (startSeconds * 6) / 3600;
      return startRem + 'rem';
    }
  };
  getHeightRem = () => {
    const startBlockSeconds =
      new Date(this.props.block.date).getHours() * 60 * 60;
    const difference =
      this.props.task.startTime + this.props.task.duration - startBlockSeconds;
    if (this.props.task.startTime < startBlockSeconds) {
      const rem =
        Math.round(((difference * 6) / 3600 + Number.EPSILON) * 100) / 100;
      return rem;
    } else {
      const rem =
        Math.round(
          ((this.props.task.duration * 6) / 3600 + Number.EPSILON) * 100
        ) / 100;
      return rem;
    }
  };

  render() {
    const {
      title,
      description,
      type,
      running,
      startTime,
      startAt
    } = this.props.task;
    return (
      <div
        className={
          startTime < this.props.block.startTime &&
          this.props.block.running === false
            ? 'd-none'
            : 'sticker-task position-absolute'
        }
        title={
          running && this.props.block.running ? title + ' running! ' : title
        }
        style={{
          top: this.getTopRem(),
          height: this.getHeightRem() + 'rem',
          //maxHeight: '6rem',
          width: parseInt(100 / this.props.cols) + '%',
          left: parseInt(100 / this.props.cols) * this.props.position + '%',
          zIndex: '9'
        }}
      >
        {this.getHeightRem() > 2 ? (
          <div className="task-container row">
            <span className="item-thumb-container col-2 col-md-2 text-center">
              {running && this.props.block.running ? (
                <i
                  className="far fa-stop-circle fa-stack-1x fa-spin float-right"
                  style={{ color: 'rgb(210, 229, 39)' }}
                ></i>
              ) : (
                ''
              )}
            </span>
            <span className="item-details-container col-8 col-md-8">
              <small
                className="task-title position-absolute"
                style={{
                  left: '-16px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {title}
              </small>
              <small
                className="task-description position-absolute"
                style={{ top: '18px', left: '-16px' }}
              >
                {type} start at
                {new Intl.DateTimeFormat('it-IT', {
                  hour: 'numeric',
                  minute: 'numeric'
                }).format(new Date(startAt))}
                {description}
              </small>
            </span>
            <span className="item-side-container col-2 "></span>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default TaskSticker;
