import React, { Component, Fragment } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import Alerts from './components/layout/Alerts';
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import { AppRouter } from './components/common/AppRouter';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import './App.css';

class App extends Component {
  alertOptions = {
    timeout: 6000,
    position: 'top center',
    transition: 'scale',
    containerStyle: {
      zIndex: '999'
    }
  };

  componentDidMount() {
    store.dispatch(loadUser());
  }

  render() {
    return (
      <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...this.alertOptions}>
          <Router>
            <Fragment>
              <Alerts />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <AppRouter />
              </MuiPickersUtilsProvider>
            </Fragment>
          </Router>
        </AlertProvider>
      </Provider>
    );
  }
}

export default App;
