import React, { Component } from 'react';

export class NotFound extends Component {
  render() {
    return (
      <div className="m-3 p-3">
        <h1 className="text-center">Error 404</h1>
        <h2 className="text-center">Page not found</h2>
      </div>
    );
  }
}

export default NotFound;
