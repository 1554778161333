import React, { Component } from 'react';
import Form from './PlanForm';

export class CreateTask extends Component {
  render() {
    return (
      <>
        <Form type="task" />
      </>
    );
  }
}

export default CreateTask;
